<template>
  <loading :active="isLoading" :can-cancel="true" :z-index="10000" :color="'var(--First-Color)'"></loading>
  <div>
    <Header />
    <Home_Component />
    <Category :vips_data="superVip" :category_data="ResponseData" />
    <!--    <Trainings/>-->
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Home_Component from '@/components/Home.vue'
import Category from '@/components/Category.vue'
// import Trainings from '@/components/Trainings.vue'
import Footer from '@/components/NewFooter.vue'

import Loading from 'vue3-loading-overlay';

export default {
  name: 'Home',
  data() {
    return {
      ResponseData: [{
        name: '',
        file: '',
        cnt: ''
      }],
      superVip: [],
      isLoading: true
    }
  },
  components: {
    Header,
    Home_Component,
    Category,
    // Trainings,
    Footer,

    Loading
  },
  methods: {
    async categoryData() { // Make this method async if needed
      try {
        const response = await this.axios.get(process.env.VUE_APP_BACKEND_URL + '/public/categories');
        this.ResponseData = response.data;
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    },
    async getSuperVip() {
      try {
        const response = await this.axios.get('https://recruting.dkcapital.ge/api/public/vacancy/super_vip_list');
        this.superVip = response.data;
      } catch (error) {
        console.error("Error fetching super VIP data:", error);
      }
    }
  },
  async mounted() {

    try {
      await this.getSuperVip();   // Wait for getSuperVip to finish
      await this.categoryData();  // Then wait for categoryData to finish
    } catch (error) {
      console.error("Error in mounted:", error);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style scoped></style>