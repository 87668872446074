<template>
  <section class="home position-relative">
    <div class="video position-absolute top-0 h-100 w-100" style="z-index: -1;">
        <video class="w-100" style="height: 100%;object-fit: cover;" loop="" muted="" autoplay=""
            playsinline="">
            <source src="https://dk.ge/backgroundVideo.mp4" type="video/mp4">
            <source src="https://dk.ge/backgroundVideo.mp4" type="video/mp4">
        </video>
    </div>
    <div class="position-absolute top-0 h-100 w-100" style="background-color: #00000054;z-index: -1;"></div>
    <div class="container">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-12 col-xl-10 text-center mx-auto mt-3 mg-lg-0" >
          <h1 class="home-title">უბრალოდ კარგი ვაკანსიები</h1>
          <div class="home-input-group">
            <!-- home-input -->
            <div class="home-input position-relative">
              <label class="text-start">რას ეძებთ?</label>
<!--              <v-select class="select-form" placeholder="სამუშაო, კომპანია" :options="[-->
<!--                        `Front-End Developer`,-->
<!--                        'მარკეტინგის სპეციალისტი',-->
<!--                        'გაყიდვები მენეჯერი'-->
<!--                    ]"></v-select>-->
              <div class="d-flex position-relative">
                <input type="text" class="select-form" name="search_key" v-model="form.searchKeyword" placeholder="პოზიცია, კომპანია" style="padding-right:20px;">
                <svg v-if="form.searchKeyword" @click="searchKeyWord" style="top:50%;right: 30px;transform: translateY(-50%);fill: #A0A0A0;cursor:pointer;" class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
              </div>
             </div>
            <!-- home-input -->
            <div class="home-input">
              <label class="text-start">სად?</label>
              <v-select class="select-form" name="region" placeholder="ადგილმდებარეობა" :reduce="(option) => option.id" label="text" :options="LocationData" v-model="form.region"></v-select>
            </div>
            <!-- home-input -->
            <div class="home-input position-relative">
              <label class="text-start">შემოსავალი</label>
              <div class="d-flex position-relative">
                <div class="w-100" @click="filterPricing">
                  <div class="w-100 h-100 bg-transparent position-absolute top-0"></div>
                  <input type="text" class="select-form" :value="pricing" placeholder="შემოსავალი">
                </div>
                <svg @click="filterPricing" :class="{active: isActive}" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 7L7.70711 6.29289L8.41421 7L7.70711 7.70711L7 7ZM1.70711 0.292893L7.70711 6.29289L6.29289 7.70711L0.292893 1.70711L1.70711 0.292893ZM7.70711 7.70711L1.70711 13.7071L0.292893 12.2929L6.29289 6.29289L7.70711 7.70711Z" fill="#424242" fill-opacity="0.5"/>
                </svg>

                <div @click="filterPricing" class="pricing-card-background" :class="{active: isActive}"></div>

                <div v-if="isActive" class="card pricing-card position-absolute top-100" style="margin-top: 6px">
                  <div class="card-body p-0">
                    <div class="pricing-input-group">
                      <div class="pricing-input">
                        <label>მინ. შემოსავალი</label>
                      <input v-model="form.minPrice" name="min_price"  type="number" placeholder="თანხა -დან">
                      </div>
                      <div class="pricing-input">
                        <label>მაქს. შემოსავალი</label>
                        <input v-model="form.maxPrice" name="max_price" type="number" placeholder="თანხა -მდე">
                      </div>
                    </div>

                    <div class="pricing-btn-group">
                      <div class="w-100">
                        <button class="home-btn" @click="pricingSave">შენახვა</button>
                      </div>
                      <div class="w-100">
                        <button class="home-btn clear-btn" @click="pricingClear">გასუფთავება</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- Search Btn -->
            <button class="home-btn" @click="filterVacancy">ძებნა</button>
            <!-- <button class="home-btn all-vacancy py-0 w-100 px-1" style="white-space: nowrap;" @click="filterVacancy">ყველა ვაკანსია</button> -->
          </div>
          <!-- <div class="top-position-container">
            <div class="d-inline-flex">
              <div  v-for="item in TopPositionData" :key="item.id" @click="MoveToFilter(item.name)">
                <button :class="item.name==form.searchKeyword ? 'active-position top-position-link':'top-position-link'">{{ item.name }}</button>
              </div>
            </div>
          </div> -->
          <!-- <a class="mt-4 d-block d-md-none" href="https://academy.dk.ge/" target="_blank">
            <img class="w-100" src="../assets/image/mobile_banner.jpg" alt="dk academy" style="border-radius: 10px;">
          </a> -->
        </div>
        <!-- <div class="col-12 col-md custom-col">
          <h1 class="home-title">ვაკანსიები, რომლებიც შეიძლება საინტერესო იყოს თქვენთვის ან თქვენი მეგობრებისთვის</h1>
          <img class="img-fluid w-100" src="../assets/image/home1.svg" alt="home image" style="object-fit: cover;max-width: 449px;float: right">
        </div> -->

        <!-- ADS -->
        <!-- <div class="col-12 col-md-10 mx-md-auto mt-5 d-none d-lg-block">
          <div class="a_box">
            <h4>სარეკლამო ადგილი</h4>
          </div>
          <a href="https://academy.dk.ge/" target="_blank">
            <img class="w-100" src="../assets/image/banner.jpg" alt="dk academy" style="border-radius: 10px;">
          </a>
        </div> -->
        <!-- End ADS -->

        <div class="col-12">
          <div class="home-input-group">
            <!-- home-input -->
            <div class="home-input position-relative">
              <label>რას ეძებთ?</label>
              <!--              <v-select class="select-form" placeholder="სამუშაო, კომპანია" :options="[-->
              <!--                        `Front-End Developer`,-->
              <!--                        'მარკეტინგის სპეციალისტი',-->
              <!--                        'გაყიდვები მენეჯერი'-->
              <!--                    ]"></v-select>-->
              <div class="d-flex position-relative">
                <input type="text" class="select-form" name="search_key" v-model="form.searchKeyword" placeholder="პოზიცია, კომპანია" style="padding-right:20px;">
                <svg v-if="form.searchKeyword" @click="searchKeyWord" style="top:50%;right: 30px;transform: translateY(-50%);fill: #A0A0A0;cursor:pointer;" class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
              </div>
            </div>
            <!-- home-input -->
            <div class="home-input">
              <label>სად?</label>
              <v-select class="select-form" name="region" placeholder="ადგილმდებარეობა" :reduce="(option) => option.id" label="text" :options="LocationData" v-model="form.region"></v-select>
            </div>
            <div class="home-input position-relative">
              <label>ხელფასი</label>
              <div class="d-flex position-relative">
                <div class="w-100" @click="filterPricing">
                  <div class="w-100 h-100 bg-transparent position-absolute top-0"></div>
                  <input type="text" class="select-form" :value="pricing" placeholder="ხელფასი">
                </div>
                <svg @click="filterPricing" :class="{active: isActive}" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 7L7.70711 6.29289L8.41421 7L7.70711 7.70711L7 7ZM1.70711 0.292893L7.70711 6.29289L6.29289 7.70711L0.292893 1.70711L1.70711 0.292893ZM7.70711 7.70711L1.70711 13.7071L0.292893 12.2929L6.29289 6.29289L7.70711 7.70711Z" fill="#424242" fill-opacity="0.5"/>
                </svg>

                <div @click="filterPricing" class="pricing-card-background" :class="{active: isActive}"></div>

                <div v-if="isActive" class="card pricing-card" style="margin-top: 6px">
                  <div class="card-body p-0">
                    <div class="pricing-input-group">
                      <div class="pricing-input">
                        <label>მინ. ხელფასი</label>
                        <input v-model="form.minPrice" name="min_price" type="number">
                      </div>
                      <div class="pricing-input">
                        <label>მაქს. ხელფასი</label>
                        <input v-model="form.maxPrice" name="max_price" type="number">
                      </div>
                    </div>

                    <div class="pricing-btn-group">
                      <div class="w-100">
                        <button class="home-btn" @click="pricingSave">შენახვა</button>
                      </div>
                      <div class="w-100">
                        <button class="home-btn bg-transparent" @click="pricingClear">გასუფთავება</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <button class="home-btn" @click="filterVacancy">ძებნა</button>
            <!-- <button class="home-btn all-vacancy py-0 w-100 px-1" style="white-space: nowrap;" @click="filterVacancy">ყველა ვაკანსია</button> -->
          </div>

          <!-- <a class="mt-5 d-block" href="https://academy.dk.ge/" target="_blank">
            <img class="w-100" src="../assets/image/banner.jpg" alt="dk academy" style="border-radius: 10px;">
          </a> -->
        </div>

        
      </div>
    </div>
  </section>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "Home",
  data() {
    return {
      form: {
        searchKeyword: '',
        region: '',
        minPrice: '',
        maxPrice: ''
      },
      LocationData: [],
      isActive: false,
      pricing: '',
      TopPositionData:[]
    }
  },
  components: {
    vSelect
  },
  methods: {
    pricingSave() {
      if(this.form.minPrice || this.form.maxPrice) {
        if(this.form.minPrice != '') {
          this.pricing = this.form.minPrice + "₾" + '-'
        }
        if(this.form.maxPrice != '') {
          this.pricing = '-' + this.form.maxPrice + "₾"
        }
        if(this.form.minPrice && this.form.maxPrice) {
          this.pricing = this.form.minPrice + "₾" + "-" + this.form.maxPrice + "₾";
        }
        this.isActive = false;
      }
    },
    pricingClear() {
      this.form.minPrice = '';
      this.form.maxPrice = '';
      this.pricing = '';
    },
    filterPricing() {
      this.isActive = !this.isActive;
    },
    searchKeyWord() {
      this.form.searchKeyword = '';
    },
    inputTwoClear() {
      this.selectLocation = '';
    },
    location() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/vacancy/LoadRegion')
          .then(response => {
            this.LocationData = response.data;
          });
    },
    LoadTopPositions() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/get_top_positions')
          .then(response => {
            this.TopPositionData = response.data;
          });
    },
    filterVacancy(){
 /*     this.$router.push({
        path: '/VacancyList',
        query: { searchKeyword: this.form.searchKeyword, region: this.form.region, minPrice: this.form.minPrice, maxPrice: this.form.maxPrice   }
      });*/
      window.location.href='/VacancyList'+'?region='+this.form.region+'&searchKeyword='+this.form.searchKeyword+'&minPrice='+this.form.minPrice+'&maxPrice='+this.form.maxPrice+'&page=';
    },
    MoveToFilter(item){
      window.location.href='/VacancyList?searchKeyword='+item;
    },
  },

  mounted() {


    if(parseInt(this.$route.query.region)){
    this.form.region=parseInt(this.$route.query.region);
    }
    if(this.$route.query.searchKeyword) {
      this.form.searchKeyword = this.$route.query.searchKeyword;
    }
    if(this.$route.query.minPrice) {
      this.form.minPrice = this.$route.query.minPrice;
    }
    if(this.$route.query.maxPrice) {
      this.form.maxPrice = this.$route.query.maxPrice;
    }
    if(this.$route.query.minPrice || this.$route.query.maxPrice) {
      this.pricing = this.$route.query.minPrice + "₾" + "-" + this.$route.query.maxPrice + "₾"
    }
    this.location();
    this.LoadTopPositions();
  }
}
</script>

<style scoped>
.home .vs--single .vs__selected {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.home .vs__dropdown-toggle {
  border: 0!important;
}
.pricing-card {
  z-index: 1003!important;
}
.pricing-card-background.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1002;
}
.home .clear-btn {
  background: #f3f3f3 !important;
}
.custom-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .custom-col {
    display: none;
  }
}

.home-btn.all-vacancy {
    background-color: #4355FF!important;
    color: #ffffff!important;
  }
.home-btn.all-vacancy:hover {
    background: #5767f7!important;
}

.a_box { 
  border: 1px solid var(--input-border);
  border-radius: 10px;

  width: 100%;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--input-background);

  transition: 0.5s;

}

.a_box:hover {
  border: 1px solid var(--input-border-hover);
}

.a_box h4 {
    font-size: 15px;
    color: #A0A0A0;
    font-family: var(--Third-Font);
    transition: 0.5s;
}

</style>