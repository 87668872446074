import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome/Welcome.vue'
import Home from '../views/Home.vue'

/* Vacancy */
import VacancyList from '../views/Vacancy/VacancyList.vue'
import VacancyDetails from '../views/Vacancy/VacancyInner.vue'

import MySentVacancies from '../views/Vacancy/user/Sent_vacancies.vue'


/* Company */
import Company from '../views/Vacancy/Company/Company.vue'
/* Pricing */
import Pricing from '../views/Vacancy/Pricing/Pricing.vue'

/* FAQ */
import FAQ from '../views/FAQ.vue'
/* Negotiations War */
import NegotiationsWar from '../views/NegotiationsWar.vue'
/* Training */
import Training from '../views/Training/Training.vue'
import TrainingInner from '../views/Training/TrainingInner.vue'
import Registration from '../views/Training/Registration.vue'

/* Auth */
import Signin from '../views/Auth/Signin.vue'
import Signup from '../views/Auth/Signup.vue'
import EditPassword from '../views/Auth/EditPassword.vue'
import Auth from '../views/Auth/index.vue'

/* 404 */
import error404 from '../views/404.vue'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    name: 'error404',
    component: error404
  },
  /* Vacancy */
  {
    path: '/VacancyList',
    name: 'VacancyList',
    component: VacancyList
  },
  {
    path: '/VacancyDetails/:id',
    name: 'VacancyDetails',
    component: VacancyDetails
  },
  {
    path: '/MySentVacancies',
    name: 'MySentVacancies',
    component: MySentVacancies
  },
  /* Company comment*/
  {
    path: '/Company/:id',
    name: 'Company',
    component: Company
  },
  /* Pricing */
  {
    path: '/Pricing',
    name: 'Pricing',
    component: Pricing
  },
  /* Signin */
  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/EditPassword',
    name: 'EditPassword',
    component: EditPassword
  },
  {
    path: '/Auth',
    name: 'Auth',
    component: Auth
  },
  /* FAQ */
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  /* Negotiations War */
  {
    path: '/NegotiationsWar',
    name: 'NegotiationsWar',
    component: NegotiationsWar
  },
  /* Trainings */
  {
    path: '/Training',
    name: 'Training',
    component: Training
  },
  {
    path: '/TrainingInner/:id',
    name: 'TrainingInner',
    component: TrainingInner
  },
  {
    path: '/Registration/:id',
    name: 'Registration',
    component: Registration
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {



  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`
  next()


  const user = (to.name === 'Signin' || to.name === 'Signup' || to.name === 'Auth');

  if (user) {
    if (localStorage.getItem('user')) {
      router.push('/')
    } else {
      next();
    }
  }

  if (!localStorage.getItem('user') && to.name === 'EditPassword') {
    router.push('/')
  }



})

export default router
