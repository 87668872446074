<template>
  <div>
    <header id="header" class="header" :class="{'bg-white': isScrolled}">
      <div class="container">
        <div class="row align-items-center">
          <nav class="col-12 d-flex align-items-center">
            <a class="logo" href="/Home">
              <img v-if="isScrolled || $route.name == 'VacancyDetails' || $route.name == 'FAQ' || $route.name == 'Company'" src="../assets/image/kenchadze_logo.svg" alt="logo">
              <img v-else src="../assets/image/white_logo.svg" alt="logo">
            </a>
            <div class="menu ms-auto" :class="isActive == true ? 'active' : ''">
              <ul class="navbar-menu">
                <li class="nav-link"><router-link :class="{'text-white': $route.name != 'VacancyDetails' && $route.name != 'FAQ' && $route.name != 'Company' && !isScrolled}" @click="navLinkForBody" to="/FAQ">ვაკანსიის განთავსება</router-link></li>
                <li class="nav-link d-none"></li>
                <li class="nav-link"><a :class="{'text-white': $route.name != 'VacancyDetails' && $route.name != 'FAQ' && $route.name != 'Company' && !isScrolled}" @click="navLinkForBody" href="https://academy.dk.ge/">აკადემია</a></li>
                <li class="nav-link btn-link">
                  <!-- <router-link v-if="!user.permission" to="/Auth">შესვლა</router-link>
                  <div v-if="user.permission" class="dropdown border-start border-dark ps-2">
                    <button class="bg-transparent border-0 dropdown-toggle d-flex align-items-center gap-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                      </svg>
                      {{user.user.name}}
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link class="dropdown-item bg-transparent d-flex align-items-center gap-2" to="/MySentVacancies">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-send-check" viewBox="0 0 16 16">
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z"/>
                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686"/>
                          </svg>
                          გაგზავნილი სივები: 0
                        </router-link>
                      </li>
                      <li v-if="user.permission" class="align-self-start">
                        <router-link class="dropdown-item bg-transparent d-flex align-items-center gap-2" to="/EditPassword">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                          </svg>
                          პაროლის შეცვლა
                        </router-link>
                      </li>
                      <li>
                        <button @click="logout()" class="dropdown-item bg-transparent d-flex align-items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                          </svg>
                          გასვლა
                        </button>
                      </li>
                    </ul>
                  </div> -->
                  <a href="https://app.dk.ge/Signin">შესვლა</a>
                </li>
              </ul>
              <ul class="navbar-menu-auth mt-0">
                <li @click="menu" v-if="!user.permission" class="nav-link-auth mt-4 pt-4 border-top w-100 justify-content-center">გაქვთ ანგარიში? 
                  <!-- <router-link v-if="!user.permission" to="/Auth"><span>შესვლა</span></router-link>  -->
                  <a href="https://app.dk.ge/Signin"><span>შესვლა</span></a> 
                </li>
                <!-- <li @click="menu" v-if="!user.permission" class="nav-link-auth"><router-link to="/Auth"><button class="btn-auth">რეგისტრაცია</button></router-link></li> -->
                <li class="nav-link-auth"><a href="https://app.dk.ge/Signup"><button class="btn-auth">რეგისტრაცია</button></a></li>

                <li v-if="user.permission" class=" border-top w-100 pt-4 mt-4 fs-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                  </svg> 
                  {{user.user.name}}
                </li>
                <li v-if="user.permission" class="align-self-start">
                  <a class="dropdown-item bg-transparent d-flex align-items-center gap-2" href="/MySentVacancies">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-send-check" viewBox="0 0 16 16">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z"/>
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686"/>
                    </svg>
                    გაგზავნილი: 0
                  </a>
                </li>
                <li v-if="user.permission" class="align-self-start">
                  <a class="dropdown-item bg-transparent d-flex align-items-center gap-2" href="/EditPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                    </svg>
                    პაროლის შეცვლა
                  </a>
                </li>
                <li v-if="user.permission" class="align-self-start">
                  <button @click="logout()" class="dropdown-item bg-transparent d-flex align-items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                      <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                    </svg>
                    გასვლა
                  </button>
                </li>
              </ul>
            </div>
            <div class="burger-container" :class="{ active: isActive, 'white-burger': $route.name != 'VacancyDetails' && $route.name != 'FAQ' && $route.name != 'Company' && !isScrolled}" @click="menu">
              <div class="burger_main-line"></div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isActive: false,
      user: {},
      isScrolled: false
    }
  },
  methods: {
    menu() {
      this.isActive = !this.isActive;

      if (document.documentElement.scrollTop < 20) {
        this.isScrolled = !this.isScrolled;
      } 
      const body = document.body;
      body.classList.toggle('overflow-hidden');
    },
    navLinkForBody() {
      const body = document.body;
      body.classList.remove('overflow-hidden');
    },
    checkUser() {
      const user = JSON.parse(localStorage.getItem('user'));
     
      if (user) {
        const token = user.access_token;

        this.axios.post(`${process.env.VUE_APP_BACKEND_URL}/auth/checking_user`, { 'data': user }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then((res) => {
            if (res.data) {
              // this.user = user;
            }
          })
          .catch((error) => {
            console.log(error);
            localStorage.removeItem('user');
            this.$router.push('/');
          });
      }
    },
    logout() {
      localStorage.removeItem('user');
      this.$router.push('/Signin');
      this.user = false;
    },

    handleScroll() {
      if (document.documentElement.scrollTop > 20) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
  mounted() {

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.user = user;
    }
    this.checkUser();

    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>

</style>