<template>
    <div>
        <Header />

        <section class="signin">
            <div class="container">
                <!-- Row -->
                <div class="row g-3 my-5 justify-content-center">
                    <!-- Col -->
                    <div class="col-12 col-md-4 my-md-5 py-md-5 d-flex">
                        <div class="card">
                            <div class="d-flex justify-content-center align-items-center py-5 bg-light w-100">
                                <svg class="my-3" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" style="fill: #4355FF;"><path d="M20.92 2.38A15.72 15.72 0 0 0 17.5 2a8.26 8.26 0 0 0-6 2.06Q9.89 5.67 8.31 7.27c-1.21-.13-4.08-.2-6 1.74a1 1 0 0 0 0 1.41l11.3 11.32a1 1 0 0 0 1.41 0c1.95-2 1.89-4.82 1.77-6l3.21-3.2c3.19-3.19 1.74-9.18 1.68-9.43a1 1 0 0 0-.76-.73zm-2.36 8.75L15 14.67a1 1 0 0 0-.27.9 6.81 6.81 0 0 1-.54 3.94L4.52 9.82a6.67 6.67 0 0 1 4-.5A1 1 0 0 0 9.39 9s1.4-1.45 3.51-3.56A6.61 6.61 0 0 1 17.5 4a14.51 14.51 0 0 1 2.33.2c.24 1.43.62 5.04-1.27 6.93z"></path><circle cx="15.73" cy="8.3" r="2"></circle><path d="M5 16c-2 1-2 5-2 5a7.81 7.81 0 0 0 5-2z"></path></svg>
                            </div>
                            <div class="card-body text-center">
                                <h5 class="card-title">გააგზავნე CV მარტივად</h5>
                                <p class="card-text">იმისათვის რომ შეძლო CV-ის მარტივად გაგზავნა ჩვენი საიტის ვაკანსიებზე საჭიროა გაიარო შესაბამის გვერდზე რეგისტრაცია.</p>
                                <router-link to="/Signin" class="home-btn all-vacancy mx-auto">შესვლა </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12 col-md-4 my-md-5 py-md-5 d-flex">
                        <div class="card">
                            <div class="d-flex justify-content-center align-items-center py-5 bg-light w-100">
                                <svg class="my-3" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" style="fill: #4355FF;"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path></svg>
                            </div>
                            <div class="card-body text-center">
                                <h5 class="card-title">შექმენი ბიზნესის პროფილი</h5>
                                <p class="card-text">იმისათვის რომ შეძლო ვაკანსიის ჩვენს საიტზე განთავსება საჭიროა გაირო შესაბამის გვერდზე რეგისტრაცია.</p>
                                <a href="https://app.dk.ge/Signin" class="home-btn all-vacancy mx-auto">შესვლა </a>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                </div>
                <!-- End Row -->
            </div>
        </section>
        
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/NewFooter.vue'

export default {
    name: 'index',
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
.home-btn {
    display: block;
    max-width: 200px;
    width: 100%;
    height: 54px;

    background: var(--First-Color);
    border: 1px solid transparent;
    border-radius: 10px;

    padding: 17px 26px 33px;

    font-family: var(--Third-Font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: lowercase;

    color: var(--Second-Color);
    transition: 0.3s;
}
.home-btn:hover {
    background: var(--Second-Color-Background-Btn-Hover);
}

.home-btn.all-vacancy {
    background-color: #4355FF!important;
    color: #ffffff!important;
  }
.home-btn.all-vacancy:hover {
    background: #5767f7!important;
}
</style>