<template>
  <div>
    <Header/>
    <section class="auth">
      <div class="container">
        <!-- Row -->
        <div class="row g-3 align-items-center">

          <!-- Col -->
          <div class="col-12 col-md-7 d-1">
            <img class="img-fluid" alt="home image" src="../../assets/image/icon-images/home.png">
          </div>
          <!-- Col -->
          <div class="col-12 col-md-5 d-flex justify-content-end d-1">
            <Form v-slot="{ handleSubmit }" class="w-100 d-block">
              <form @submit.prevent="handleSubmit(submitForm)" class="auth-container">
               <h1 class="auth-title mb-0 fs-3">
                 გააგზავნე CV მარტივად
               </h1>
                <h4 class="auth-title">შესვლა</h4>
                <div>
                  <Field name="email" v-model="form.email" :rules="validateEmail" type="email" class="auth-control d-block" placeholder="ელ.ფოსტა" />
                  <ErrorMessage class="text-danger" name="email" />
                </div>

                <div>
                  <Field name="password" v-model="form.password"  :rules="minPassword" type="password" class="auth-control" placeholder="პაროლი" />
                  <ErrorMessage class="text-danger" name="password" />
                </div>
                <div class="d-flex flex-column">
                  <div class="text-center">
                    <span>არ გაქვთ ანგარიში?</span>
                    <router-link to="/Signup">რეგისტრაცია</router-link>
                  </div>
                  <button type="submit" class="btn-auth">შესვლა</button>
                </div>
              </form>
            </Form>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/NewFooter.vue'

import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
  name: "Signin",
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  components: {
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    scrollY() {
      window.scrollTo(0, 0)
    },
    submitForm() {
      this.axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/login', this.form)
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data));
          /*this.$store.state.user_role=res.role;*/


          this.$router.push('/');

        })
        .catch((error) => {
          console.log(error);
        });
    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        return 'აუცილებელი ველი';
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
      }
      // All is good
      return true;
    },
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return 'აუცილებელი ველი';
    },
    minPassword(value) {
      if (value && value.length < 8) {
        return 'პაროლი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან';
      }
      if (value && value.trim()) {
        return true;
      }
      return 'აუცილებელი ველი';
    },
  },
  mounted() {
    this.scrollY();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}
</style>