<template>
    <div>
        <Header />

        <section class="signin">
            <div class="container my-md-4 py-md-4">
                <!-- Row -->
                <div class="row g-3 my-5">
                    <div class="col-12 col-md-8 mb-md-3">
                        <h1 class="font-weight-bold fs-5">ჩემი გაგზავნილი სივები</h1>
                    </div>
                    <!-- <div class="col-12 col-md-4 text-md-end mb-md-3">
                        <span>რაოდენობა: 5</span>
                    </div> -->

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body d-flex justify-content-between">
                                <div class="card-left-detail">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
                                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
                                    </svg>
                                    
                                </div>
                                <div class="card-right-detail">
                                    <span><small>სულ გაგზავნილი სივები:</small> 5</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Col -->
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <!-- <div class="card-header bg-white">
                                <small>სტატუსი: </small>
                                <span class="badge text-bg-warning">განსახილველი</span>
                            </div> -->
                            <div class="card-body">
                                <h5 class="card-title">გაყიდვების მენეჯერი</h5>
                                <p class="card-text">კომპანია: ვისოლი</p>
                                <a href="#" class="home-btn me-2 mb-1 mb-md-0">
                                    ვაკანსიის ნახვა
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                                        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg>
                                </a>
                                <a class="btn btn-light border rounded-3" href="#">
                                    cv /
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <!-- <div class="card-header bg-white">
                                <small>სტატუსი: </small>
                                <span class="badge text-bg-success">აყვანილი</span>
                            </div> -->
                            <div class="card-body">
                                <h5 class="card-title">გაყიდვების მენეჯერი</h5>
                                <p class="card-text">კომპანია: ვისოლი</p>
                                <a href="#" class="home-btn me-2 mb-1 mb-md-0">
                                    ვაკანსიის ნახვა
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                                        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg>
                                </a>
                                <a class="btn btn-light border rounded-3" href="#">
                                    cv /
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <!-- <div class="card-header bg-white">
                                <small>სტატუსი: </small>
                                <span class="badge text-bg-danger">უარყოფილი</span>
                            </div> -->
                            <div class="card-body">
                                <h5 class="card-title">გაყიდვების მენეჯერი</h5>
                                <p class="card-text">კომპანია: ვისოლი</p>
                                <a href="#" class="home-btn me-2 mb-1 mb-md-0">
                                    ვაკანსიის ნახვა
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                                        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg>
                                </a>
                                <a class="btn btn-light border rounded-3" href="#">
                                    cv /
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                </div>
                <!-- End Row -->
            </div>
        </section>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/NewFooter.vue'

export default {
    name: 'Sent_vacancies',
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
.home-btn {
    display: inline-block;

    background-color: #4355FF !important;

    border: 1px solid transparent;
    border-radius: 10px;

    padding: 10px 17px;

    font-family: var(--Third-Font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: lowercase;

    color: #ffffff !important;
    transition: 0.3s;
}

.home-btn:hover {
    background: #5767f7 !important;
}

.signin h1, .signin h2, .signin h3, .signin h4, .signin h5, .signin h6 {
    font-family: var(--First-Font);
    font-size: 18px;
}
.signin p, .signin a, .signin small, .signin span {
    font-family: var(--Third-Font);
}
</style>