<template>
    <div>
        <Header />
        <section class="auth">
            <div class="container">
                <!-- Row -->
                <div class="row g-3 align-items-center">

                    <!-- Col -->
                    <div class="col-12 col-md-7 d-1">
                        <img class="img-fluid" alt="home image" src="../../assets/image/icon-images/home.png">
                    </div>
                    <!-- Col -->
                    <div class="col-12 col-md-5 d-flex justify-content-end d-1">
                        <Form v-slot="{ handleSubmit }" class="w-100 d-block">
                            <form @submit.prevent="handleSubmit(submitForm)" class="auth-container">
                                <h1 class="auth-title mb-0 fs-3">
                                    გააგზავნე CV მარტივად
                                </h1>
                                <h4 class="auth-title">რეგისტრაცია</h4>
                                <div>
                                    <Field name="name" v-model="form.name" :rules="isRequired" type="name"
                                        class="auth-control d-block" placeholder="სახელი და გვარი" />
                                    <ErrorMessage class="text-danger" name="name" />
                                </div>

                                <div>
                                    <Field name="email" v-model="form.email" :rules="validateEmail" type="email"
                                        class="auth-control d-block" placeholder="ელ.ფოსტა" />
                                    <ErrorMessage class="text-danger" name="email" />
                                </div>

                                <div>
                                    <Field name="password" v-model="form.password" :rules="minPassword" type="password"
                                        class="auth-control" placeholder="პაროლი" />
                                    <ErrorMessage class="text-danger" name="password" />
                                </div>
                                <div class="d-flex align-items-start flex-column">
                                   
                                    <label class="ms-1">
                                        <Field name="terms" :rules="isRequiredTerms" v-slot="{ field }">
                                            <input class="cursor-pointer" type="checkbox" v-bind="field" v-model="form.terms" />
                                        </Field>
                                        მე ვეთანხმები პირობებს <a class="terms" href="https://dk.ge/terms.pdf" target="_blank"><b>პირობები</b></a>
                                    </label>
                                    <ErrorMessage class="text-danger ms-1 text-sm" name="terms" />
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="text-center">
                                        <span>უკვე თუ გაქვთ ანგარიში?</span>
                                        <router-link to="/Signin">შესვლა</router-link>
                                    </div>
                                    <button type="submit" class="btn-auth">რეგისტრაცია</button>
                                </div>
                            </form>
                        </Form>
                    </div>
                    <!--  End Col -->
                </div>
                <!-- End Row -->
            </div>
        </section>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/NewFooter.vue'

import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
    name: "Signup",
    data() {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                terms: false
            }
        }
    },
    components: {
        Header,
        Footer,
        Form,
        Field,
        ErrorMessage
    },
    methods: {
        scrollY() {
            window.scrollTo(0, 0)
        },
        submitForm() {
            this.axios.post(process.env.VUE_APP_BACKEND_URL + '/public/signup', this.form).then(
                res => {
                    localStorage.setItem('user',JSON.stringify(res.data))
                    // window.location ='/';
                }
            ).catch(error => {
                console.log(error)
            })
        },
        validateEmail(value) {
            // if the field is empty
            if (!value) {
                return 'აუცილებელი ველი';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
            }
            // All is good
            return true;
        },
        isRequired(value) {
            if (value && value.trim()) {
                return true;
            }
            return 'აუცილებელი ველი';
        },
        isRequiredTerms(value) {
            if (value) {
                return true;
            }
            return 'აუცილებელად მოსანიშნი';
        },
        minPassword(value) {
            if (value && value.length < 8) {
                return 'პაროლი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან';
            }
            if (value && value.trim()) {
                return true;
            }
            return 'აუცილებელი ველი';
        },
    },
    mounted() {
        this.scrollY();
    }
}
</script>
  
<style scoped>
.footer {
    margin-top: 106px;
}
input.cursor-pointer {
  width: 15px;
  height: 15px;
  border: 1px solid #4355FF;
}
.terms { 
  color: #4355FF;
  -webkit-font-feature-settings: 'case' on!;
  font-feature-settings: "case" on;
  font-size: 14px;
  font-family: var(--First-Font);
}
.terms:hover { 
  color: #0920ec;
}
</style>